import React, { useState } from 'react';

// A functional component to render the sidebar menu
const SidebarMenu = ({ sectionGroupsChange, onItemSelect, onTextureRepeat, onImageSelect, onTextureScale, onTextureRotate, onTexturePosition }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [repeat, setRepeat] = useState({ x: 0, y: 0 });

    // Sample data for the categories and images
    const categories = {
        Tiles: [
            {
                url: './textures/Tiles/brick1k/brick_wall_04_diff_1k.png',
                baseColor: './textures/Tiles/brick1k/brick_wall_04_diff_1k.png',
                height: '',
                aomap: './textures/Tiles/brick1k/brick_wall_04_ao_1k.jpg',
                metallic: '',
                normalmap: './textures/Tiles/brick1k/brick_wall_04_nor_gl_1k.png',
                roughness: './textures/Tiles/brick1k/brick_wall_04_rough_1k.png',
                displacement: './textures/Tiles/brick1k/brick_wall_04_disp_1k.png',
                name: '1k brick'
            },
            {
                url: './textures/Tiles/brick2k/brick_wall_04_diff_2k.png',
                baseColor: './textures/Tiles/brick2k/brick_wall_04_diff_2k.png',
                height: '',
                aomap: './textures/Tiles/brick2k/brick_wall_04_ao_2k.jpg',
                metallic: '',
                normalmap: './textures/Tiles/brick2k/brick_wall_04_nor_gl_2k.png',
                roughness: './textures/Tiles/brick2k/brick_wall_04_rough_2k.png',
                displacement: './textures/Tiles/brick2k/brick_wall_04_disp_2k.png',
                name: '2k brick'
            },
            {
                url: './textures/textures 3/square_tiles_02_diff_2k.png',
                baseColor: './textures/textures 3/square_tiles_02_diff_2k.png',
                height: '',
                aomap: './textures/textures 3/square_tiles_02_ao_2k.jpg',
                metallic: '',
                normalmap: './textures/textures 3/square_tiles_02_nor_dx_2k.png',
                roughness: './textures/textures 3/square_tiles_02_rough_2k.png',
                displacement: './textures/textures 3/square_tiles_02_disp_2k.png',
                name: 'larger'
            },
            {
                url: './textures/Tiles/tile1/Tiles_048_basecolor.jpg',
                baseColor: './textures/Tiles/tile1/Tiles_048_basecolor.jpg',
                height: './textures/Tiles/tile1/Tiles_048_height.png',
                aomap: './textures/Tiles/tile1/Tiles_048_ambientOcclusion.jpg',
                metallic: './textures/Tiles/tile1/Tiles_048_metallic.jpg',
                normalmap: './textures/Tiles/tile1/Tiles_048_normal.jpg',
                roughness: './textures/Tiles/tile1/Tiles_048_roughness.jpg',
                displacement: '',
                name: 'White Gold'
            },

            {
                url: './textures/Tiles/tile2/untitled2.png',
                baseColor: './textures/Tiles/tile2/Stylized_Terracotta_Tiles_001_basecolor.png',
                height: './textures/Tiles/tile2/Stylized_Terracotta_Tiles_001_height.png',
                aomap: './textures/Tiles/tile2/Stylized_Terracotta_Tiles_001_ambientOcclusion.png',
                metallic: '',
                normalmap: './textures/Tiles/tile2/Stylized_Terracotta_Tiles_001_normal.png',
                roughness: './textures/Tiles/tile2/Stylized_Terracotta_Tiles_001_roughness.png',
                displacement: '',
                name: 'Brown '
            },
            {
                url: './textures/Tiles/tile3/Portuguese_Tiles_006_basecolor.png',
                baseColor: './textures/Tiles/tile3/Portuguese_Tiles_006_basecolor.png',
                height: './textures/Tiles/tile3/Portuguese_Tiles_006_height.png',
                aomap: './textures/Tiles/tile3/Portuguese_Tiles_006_ambientOcclusion.png',
                metallic: '',
                normalmap: './textures/Tiles/tile3/Portuguese_Tiles_006_normal.png',
                roughness: './textures/Tiles/tile3/Portuguese_Tiles_006_roughness.png',
                displacement: '',
                name: 'Blue White'
            },
            {
                url: './textures/Tiles/tile4/TilesSquarePoolMixed001_Sphere.png',
                baseColor: './textures/Tiles/tile4/TilesSquarePoolMixed001_COL_1K.jpg',
                height: '',
                aomap: '',
                metallic: '',
                normalmap: './textures/Tiles/tile4/TilesSquarePoolMixed001_NRM_1K.jpg',
                roughness: '',
                displacement: './textures/Tiles/tile4/TilesSquarePoolMixed001_DISP_1K.jpg',

                name: 'Blue Square'
            },

            {
                url: './textures/Tiles/tile5/Poliigon_StoneQuartzite_8060_Preview1.png',
                baseColor: './textures/Tiles/tile5/Poliigon_StoneQuartzite_8060_BaseColor.jpg',
                height: '',
                aomap: './textures/Tiles/tile5/Poliigon_StoneQuartzite_8060_AmbientOcclusion.png',
                metallic: './textures/Tiles/tile5/Poliigon_StoneQuartzite_8060_Metallic.jpg',
                normalmap: './textures/Tiles/tile5/Poliigon_StoneQuartzite_8060_Normal.png',
                roughness: './textures/Tiles/tile5/Poliigon_StoneQuartzite_8060_Roughness.png',
                displacement: '',
                name: 'LightBej'
            },
            {
                url: './textures/Tiles/tile6/TilesTravertine001_Sphere.png',
                baseColor: './textures/Tiles/tile6/TilesTravertine001_COL_1K.jpg',
                height: '',
                aomap: './textures/Tiles/tile6/TilesTravertine001_AO_1K.jpg',
                metallic: '',
                normalmap: './textures/Tiles/tile6/TilesTravertine001_NRM_1K.jpg',
                roughness: '',
                displacement: './textures/Tiles/tile6/TilesTravertine001_DISP_1K.jpg',
                name: 'Square Bej'
            },

            {
                url: './textures/Tiles/tile7/untitled4.png',
                baseColor: './textures/Tiles/tile7/Stylized_Tiles_003_basecolor.png',
                height: './textures/Tiles/tile7/Stylized_Tiles_003_height.png',
                aomap: './textures/Tiles/tile7/Stylized_Tiles_003_ambientOcclusion.png',
                metallic: '',
                normalmap: './textures/Tiles/tile7/Stylized_Tiles_003_normal.png',
                roughness: './textures/Tiles/tile7/Stylized_Tiles_003_roughness.png',
                displacement: '',
                name: 'Blue'
            },
            {
                url: './textures/Tiles/tile8/Metal_ArtDeco_Tiles_001_basecolor.jpg',
                baseColor: './textures/Tiles/tile8/Metal_ArtDeco_Tiles_001_basecolor.jpg',
                height: './textures/Tiles/tile8/Metal_ArtDeco_Tiles_001_height.png',
                aomap: './textures/Tiles/tile8/Metal_ArtDeco_Tiles_001_ambientOcclusion.jpg',
                metallic: './textures/Tiles/tile8/Metal_ArtDeco_Tiles_001_metallic.jpg',
                normalmap: './textures/Tiles/tile8/Metal_ArtDeco_Tiles_001_normal (1).jpg',
                roughness: './textures/Tiles/tile8/Metal_ArtDeco_Tiles_001_roughness.jpg',
                displacement: '',
                name: 'Metaly'
            },

        ],
        Marbels: [
            {
                url: './textures/Tiles/marbeltile1/Marble_Gray_002_basecolor.jpg',
                baseColor: './textures/Tiles/marbeltile1/Marble_Gray_002_basecolor.jpg',
                height: './textures/Tiles/marbeltile1/Marble_Gray_002_height.png',
                aomap: './textures/Tiles/marbeltile1/Marble_Gray_002_ambientOcclusion.jpg',
                metallic: '',
                normalmap: './textures/Tiles/marbeltile1/Marble_Gray_002_normal.jpg',
                roughness: './textures/Tiles/marbeltile1/Marble_Gray_002_roughness.jpg',
                displacement: '',
                name: 'Dark '
            },

            {
                url: './textures/Tiles/marbeltile2/Marble_Red_004_basecolor.jpg',
                baseColor: './textures/Tiles/marbeltile2/Marble_Red_004_basecolor.jpg',
                height: './textures/Tiles/marbeltile2/Marble_Red_004_height.png',
                aomap: './textures/Tiles/marbeltile2/Marble_Red_004_ambientOcclusion.jpg',
                metallic: '',
                normalmap: './textures/Tiles/marbeltile2/Marble_Red_004_normal.jpg',
                roughness: './textures/Tiles/marbeltile2/Marble_Red_004_roughness.jpg',
                displacement: '',
                name: 'Red'

            },
            {
                url: './textures/Tiles/marbeltile3/Marble_Tiles_001_basecolor.jpg',
                baseColor: './textures/Tiles/marbeltile3/Marble_Tiles_001_basecolor.jpg',
                height: './textures/Tiles/marbeltile3/Marble_Tiles_001_height.png',
                aomap: './textures/Tiles/marbeltile3/Marble_Tiles_001_ambientOcclusion.jpg',
                metallic: '',
                normalmap: './textures/Tiles/marbeltile3/Marble_Tiles_001_normal.jpg',
                roughness: './textures/Tiles/marbeltile3/Marble_Tiles_001_roughness.jpg',
                displacement: '',
                name: 'Light'
            },
        ],
        Objects: [
            { url: './textures/object1.png', name: 'Object 1' },
            { url: './textures/object2.png', name: 'Object 2' },
        ],
        Lighting: [
            { url: './textures/lighting1.png', name: 'Light 1' },
            { url: './textures/lighting2.png', name: 'Light 2' },
        ],
        // Add more categories...
    };

    console.log('sectionGroups in side', sectionGroupsChange)

    const [selectedItem, setSelectedItem] = useState('');

    // Function to handle dropdown change
    const handleDropdownChange = (event) => {

        const selectedValue = event.target.value;
        setSelectedItem(selectedValue);
        const selectedObject = sectionGroupsChange.find((item) => item.name === selectedValue);
        if (selectedObject) {
            onItemSelect(selectedObject);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category !== selectedCategory ? category : null);
    };

    const handleScaleChange = (e) => {
        setScale(e.target.value);
        onTextureScale(scale)
    };

    const handleRotationChange = (e) => {
        setRotation(e.target.value);
        onTextureRotate(e.target.value)
    };

    const handlePositionChange = (axis, value) => {
        setPosition((prev) => ({ ...prev, [axis]: value }));
        onTexturePosition(position)

    };

    const handleRepeatXChange = (axis, value) => {
        setRepeat((prev) => ({ ...prev, [axis]: value }));
        onTextureRepeat(repeat)

    };

    const handleRepeatYChange = (axis, value) => {
        setRepeat((prev) => ({ ...prev, [axis]: value }));
        onTextureRepeat(repeat)

    };




    const styles = {
        sidebar: {
            width: '250px',
            backgroundColor: '#f4f4f4',
            padding: '15px',
            boxShadow: '2px 0px 5px rgba(0,0,0,0.1)',
            overflowY: 'auto',
            height: '100vh',
            boxSizing: 'border-box',
        },
        dropdown: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            marginBottom: '20px',
        },
        categoryButton: {
            width: '100%',
            padding: '10px',
            textAlign: 'left',
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            cursor: 'pointer',
            marginBottom: '5px',
        },
        submenu: {
            paddingLeft: '15px',
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
            cursor: 'pointer',
        },
        image: {
            width: '40px',
            height: '40px',
            borderRadius: '50%', // Make the image circular
            objectFit: 'cover',   // Ensure the image covers the area
            marginRight: '10px',
        },
        imageText: {
            margin: 0,
            fontSize: '14px',
            color: '#333',
        },
        sliderContainer: {
            margin: '10px 0',
        },
        sliderLabel: {
            fontSize: '14px',
            color: '#333',
        },
        slider: {
            width: '100%',
        },
    };



    return (
        <div style={styles.sidebar}>


            {/* If sectionGroups is empty, show a message */}
            {sectionGroupsChange.length === 0 ? (
                <p>No groups available. Please load a model.</p>
            ) : (
                <select value={selectedItem} onChange={handleDropdownChange} style={{ width: '100%', padding: '10px', fontSize: '16px', marginBottom: '20px' }}>
                    <option value="" disabled>Select a group</option>
                    {sectionGroupsChange.map((group) => (
                        <option key={group.id} value={group.name}>
                            {group.name}
                        </option>
                    ))}
                </select>
            )}

            {/* Dropdown list to select an item */}
            {/* <select
                value={selectedItem}
                onChange={handleDropdownChange}
                style={styles.dropdown}
            >
                <option value="" disabled>
                    Sections
                </option>
                {
                // console.load('sectionGroups', sectionGroups)
                sectionGroups.map((item) => (
                <option key={item.id} value={item.name}>
                    {item.name}
                </option>
                ))}
            </select> */}
            {Object.keys(categories).map((category) => (
                <div key={category}>
                    <button
                        onClick={() => handleCategoryClick(category)}
                        style={styles.categoryButton}
                    >
                        {category}
                    </button>
                    {selectedCategory === category && (
                        <div style={styles.submenu}>
                            {categories[category].map((item) => (
                                <div
                                    key={item.url}
                                    style={styles.imageContainer}
                                    onClick={() => onImageSelect(item)}
                                >
                                    <img src={item.url} alt={item.name} style={styles.image} />
                                    <p style={styles.imageText}>{item.name}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}

            <div style={styles.sliderContainer}>
                <label style={styles.sliderLabel}>Scale: {scale}</label>
                <input
                    type="range"
                    min="0.5"
                    max="100"
                    step="0.1"
                    value={scale}
                    onChange={handleScaleChange}
                    style={styles.slider}
                />
            </div>
            <div style={styles.sliderContainer}>
                <label style={styles.sliderLabel}>Rotation: {rotation}°</label>
                <input
                    type="range"
                    min="0"
                    max="360"
                    step="0.1"
                    value={rotation}
                    onChange={handleRotationChange}
                    style={styles.slider}
                />
            </div>
            <div style={styles.sliderContainer}>
                {/* <label style={styles.sliderLabel}>Repeat X: {repeat.x}</label>
                <input
                    type="range"
                    min="1"
                    max="150"
                    step="0.5"
                    value={repeat.x}
                    onChange={(e) => handleRepeatXChange('x', e.target.value)}
                    style={styles.slider}
                />
                <label style={styles.sliderLabel}>Repeat Y: {repeat.y}</label>
                <input
                    type="range"
                    min="1"
                    max="100"
                    step="1"
                    value={repeat.y}
                    onChange={(e) => handleRepeatYChange('y', e.target.value)}
                    style={styles.slider}
                /> */}
                <label style={styles.sliderLabel}>Position X: {position.x}</label>
                <input
                    type="range"
                    min="-10"
                    max="10"
                    step="0.1"
                    value={position.x}
                    onChange={(e) => handlePositionChange('x', e.target.value)}
                    style={styles.slider}
                />
                <label style={styles.sliderLabel}>Position Y: {position.y}</label>
                <input
                    type="range"
                    min="-10"
                    max="10"
                    step="0.1"
                    value={position.y}
                    onChange={(e) => handlePositionChange('y', e.target.value)}
                    style={styles.slider}
                />
            </div>

        </div>
    );
};
export default SidebarMenu;