import React, { useState } from 'react';

const Panel = ({resetScene , firstPersonMode, orbitMode, deleteSelected, addModel, transformMode, loadModel, dragMode, cameraMode, changeTexture, switchView, resetCamera }) => {

    const textures = [
        {
            'diff': './textures/kitchen_wood_1k/textures/kitchen_wood_diff_1k.png',
            'norgl': './textures/kitchen_wood_1k/textures/kitchen_wood_nor_gl_1k.png',
            'aomap': './textures/kitchen_wood_1k/textures/kitchen_wood_ao_1k.png',
        }
        ,

        // {
        //     'diff': './textures/synthetic_wood_1k/textures/synthetic_wood_diff_1k.png',
        //     'norgl': './textures/synthetic_wood_1k/textures/synthetic_wood_nor_gl_1k.png',
        //     'aomap': './textures/synthetic_wood_1k/textures/synthetic_wood_ao_1k.png',
        // }
        // ,

        {
            'diff': './textures/wood_cabinet_worn_long_1k/textures/wood_cabinet_worn_long_diff_1k.png',
            'norgl': './textures/wood_cabinet_worn_long_1k/textures/wood_cabinet_worn_long_nor_gl_1k.png',
            'aomap': './textures/wood_cabinet_worn_long_1k/textures/wood_cabinet_worn_long_ao_1k.png',
        },
        {
            'diff': './textures/red_brick_03_1k/textures/red_brick_03_diff_1k.png',
            'norgl': './textures/red_brick_03_1k/textures/red_brick_03_nor_gl_1k.png',
            'aomap': './textures/red_brick_03_1k/textures/red_brick_03_arm_1k.png',
        },
        {
            'diff': './textures/worn_corrugated_iron_2k/textures/worn_corrugated_iron_diff_2k.png',
            'norgl': './textures/worn_corrugated_iron_2k/textures/worn_corrugated_iron_nor_gl_2k.png',
            'aomap': './textures/worn_corrugated_iron_2k/textures/worn_corrugated_iron_arm_2k.png',
        },
        {
            'diff': './textures/rounded_square_tiled_wall_4k/textures/rounded_square_tiled_wall_diff_4k.png',
            'norgl': './textures/rounded_square_tiled_wall_4k/textures/rounded_square_tiled_wall_nor_gl_4k.png',
            'aomap': './textures/rounded_square_tiled_wall_4k/textures/rounded_square_tiled_wall_arm_4k.png',
        },
        {
            'diff': './textures/textures/diagonal_parquet_diff_2k.png',
            'norgl': './textures/textures/diagonal_parquet_nor_gl_2k.png',
            'aomap': './textures/textures/diagonal_parquet_arm_2k.png',
        },
        // {
        //     'diff': './textures/newwood/Door_Wood_001_basecolor.jpg',
        //     'norgl': './textures/newwood/Door_Wood_001_normal.jpg',
        //     'aomap': './textures/newwood/Door_Wood_001_ambientOcclusion.jpg',
        //     'height': './textures/newwood/Door_Wood_001_height.png',
        // },
        {
            'diff': './models/shirt/FabricPattern_03_height.png',
            'height': './models/shirt/FabricPattern_03_height.png',
            'norgl': './models/shirt/FabricPattern_03_normal.png',
            'aomap': './models/shirt/FabricPattern_03_ambientOcclusion.png',
        },

        {
            'diff': './models/newShirt/Cotton1/25/BaseColor.jpg',
            'height': './models/newShirt/Cotton1/25/HeightMap.jpg',
            'norgl': './models/newShirt/Cotton1/25/Normal.jpg',
            'roughness': './models/newShirt/Cotton1/plane_divided_Cotton_Roughness.jpg',
        },


    ];

    const categories = {
        Tiles: [
            { url: './textures/Tiles/tile1/Tiles_048_basecolor.jpg', name: 'Tile 1' },
            { url: './textures/Tiles/tile2/untitled2.png', name: 'Tile 2' },
            { url: './textures/Tiles/tile3/Portuguese_Tiles_006_basecolor.png', name: 'Tile 3' },
            { url: './textures/Tiles/tile4/TilesSquarePoolMixed001_Sphere.png', name: 'Tile 4' },
            { url: './textures/Tiles/tile5/Tiles_048_basecolor.jpg', name: 'Tile 5' },
            { url: './textures/Tiles/tile6/Tiles_048_basecolor.jpg', name: 'Tile 6' },
            { url: './textures/Tiles/tile7/Tiles_048_basecolor.jpg', name: 'Tile 7' },
            { url: './textures/Tiles/tile8/Tiles_048_basecolor.jpg', name: 'Tile 8' },
        ],
        Objects: [
            { url: './textures/object1.png', name: 'Object 1' },
            { url: './textures/object2.png', name: 'Object 2' },
        ],
        Lighting: [
            { url: './textures/light1.png', name: 'Light 1' },
            { url: './textures/light2.png', name: 'Light 2' },
        ],
        // Add more categories as needed...
    };

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category === selectedCategory ? null : category);
    };

    const handleImageSelect = (url) => {
        console.log("Selected Image URL: ", url);
        changeTexture(url); // Pass the image URL to the texture change function
    };




    return (
        <div style={styles.panel}>
            {/* <label >MyHomeSpec</label> */}
            <label >Camera</label>
            <div lassName="scrollable" style={styles.scrollableContainer}>

                <button style={styles.button} onClick={() =>
                    firstPersonMode()
                }>FirstPerson  </button>
                <button style={styles.button} onClick={() =>
                    cameraMode('orthographic')
                }>2D  </button>

                <button style={styles.button} onClick={() =>
                    cameraMode('orthographic simple')
                }>2D Simple </button>

                <button style={styles.button} onClick={() =>
                    cameraMode('perspective')
                }>3D  </button>


                <div style={styles.buttonContainer}>

                    <button style={styles.button3} onClick={() =>
                        resetCamera()
                    }>Reset Camera </button>
                    <button style={styles.button3} onClick={() =>
                        resetScene()
                    }>Reset Scene </button>

                </div>
                <div style={styles.buttonContainer}>

                    <button style={styles.button2} onClick={() =>
                        dragMode()
                    }>Drag mode  </button>


                    <button style={styles.button2} onClick={() =>
                        transformMode()
                    }>Transform mode  </button>

                    <button style={styles.button2} onClick={() =>
                        orbitMode()
                    }>Orbit mode  </button>

                    <button style={styles.button2} onClick={() => deleteSelected()}>Delete Selected</button>

                </div>
                <br></br>
                <div style={styles.circularContainer}>
                    <button style={{ ...styles.viewButton, ...styles.top }} onClick={() => switchView('back')}>Back</button>
                    <button style={{ ...styles.viewButton, ...styles.left }} onClick={() => switchView('left')}>Left</button>
                    <button style={{ ...styles.viewButton, ...styles.center }} onClick={() => switchView('top')}>Top</button>
                    <button style={{ ...styles.viewButton, ...styles.right }} onClick={() => switchView('right')}>Right</button>
                    <button style={{ ...styles.viewButton, ...styles.bottom }} onClick={() => switchView('front')}>Front</button>
                </div>

                <br></br>
                <br></br>


                <label >Funniture</label>

                <div style={styles.buttonContainer}>

                    <div style={styles.imageButton} onClick={() => addModel('./models/sofa/sofa.glb')}>
                        <img src="./img/couch.png" alt="Add Sofa" style={styles.img} />
                        <span style={styles.label}>Add Sofa</span>
                    </div>

                    <div style={styles.imageButton} onClick={() => addModel('./models/sofa/light_wood_home_desk.glb')}>
                        <img src="./img/office-table.png" alt="Add Desk" style={styles.img} />
                        <span style={styles.label}>Add Desk</span>
                    </div>


                </div>
                <label >Texture</label>

                <div style={styles.textureContainer}>
                    {textures.map((texture, index) => (
                        <div
                            key={index}
                            style={{ ...styles.textureCircle, backgroundImage: `url(${texture.diff})` }}
                            onClick={() => changeTexture(texture)}
                        />
                    ))}
                </div>

                <label >Models</label>

                <div style={styles.buttonContainer}>

                    <button style={styles.button} onClick={() => loadModel('./models/newblender/origintocursor.glb')}>origincursor </button>
                    <button style={styles.button} onClick={() => loadModel('./models/newblender/origintogeometry.glb')}>origingeometry </button>
                    <button style={styles.button} onClick={() => loadModel('./models/kasra/Test (1).glb')}>kasra </button>
                    


                    <button style={styles.button} onClick={() => loadModel('./models/jabari/smallhome test edit 4.2 E.glb')}>jabari 4E</button>
                    <button style={styles.button} onClick={() => loadModel('./models/jabari/smallhome test edit 4.2 P.glb')}>jabari 4 P</button>

                    <button style={styles.button} onClick={() => loadModel('./models/fatahian/untitledgltf.glb')}>fatahian</button>
                    <button style={styles.button} onClick={() => loadModel('./models/sadegh/Room 1 (2).glb')}>sadegh</button>

                    <button style={styles.button} onClick={() => loadModel('./models/sherafat/group_test (1).glb')}>sherafat</button>
                    <button style={styles.button} onClick={() => loadModel('./models/sherafat/Newparisa_livingroom_edited.glb')}>living room</button>


                    <button style={styles.button} onClick={() => loadModel('./models/pakistan/RADAR.glb')}>RADAR</button>
                    <button style={styles.button} onClick={() => loadModel('./models/pakistan/GUNv_2.glb')}>Gun</button>
                    <button style={styles.button} onClick={() => loadModel('./models/pakistan/GLB_54MB.glb')}>glb</button>


                    <button style={styles.button} onClick={() => loadModel('./models/Test.glb')}>Test</button>
                    <button style={styles.button} onClick={() => loadModel('./models/roomapply.glb')}>Room transform </button>
                    <button style={styles.button} onClick={() => loadModel('./models/bar_diorama.glb')}>Bar </button>

                    <button style={styles.button} onClick={() => loadModel('./models/smallhome_transformapply.glb')}>transform home </button>
                    <button style={styles.button} onClick={() => loadModel('./models/smallhome_apply.glb')}>scale home </button>
                    <button style={styles.button} onClick={() => loadModel('./models/smallhome.glb')}>small home </button>
                    <button style={styles.button} onClick={() => loadModel('./models/loft__baked.glb')}>home </button>

                    <button style={styles.button} onClick={() => loadModel('./models/Apartment3-belnder4-2.glb')}>Plan1 uv </button>

                    <button style={styles.button} onClick={() => loadModel('./models/Apartment-newparisa8.glb')}>Plan1 </button>

                    <button style={styles.button} onClick={() => loadModel('./models/small_office.glb')}>Office </button>


                    <button style={styles.button} onClick={() => loadModel('./models/villa_-_a-frame_house_-_mid_century_modern.glb')}>* Floor 1 </button>
                    <button style={styles.button} onClick={() => loadModel('./models/tuscan_villa_-_italian_villa.glb')}> * Floor 2 </button>
                    <button style={styles.button} onClick={() => loadModel('./models/bali_villa._3_level_house._hillside._pauls_place.glb')}>* Floor 3 </button>



                    {/* <button style={styles.button} onClick={() => loadModel('./models/cafe-misti.glb')}>Cafe </button>
                <button style={styles.button} onClick={() => loadModel('./models/modern_tower_office_apartment_building.glb')}>Tower </button>
                <button style={styles.button} onClick={() => loadModel('./models/modern_bathroom.glb')}>Bath  </button> */}

                </div>

            </div>
        </div>
    );
};


const styles = {

    
    panel: {
        width: '450px',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        padding: '10px',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
    },
    //   scrollableContainer: {
    //     maxHeight: '1000px', // Change the height as needed
    //     overflowY: 'auto', // Add scrolling for overflowing content
    //     width: '100%',
    //   },

      scrollableContainer: {
        flex: 1, // Use all remaining space in the panel
        overflowY: 'auto',
        width: '100%',
      },

      
    button: {
        width: '45%', // Reduced width to fit two buttons per row
        padding: '10px',
        margin: '5px', // Adjust margin for spacing
        // backgroundColor: 'rgba(66, 21, 103, 0.8)',
        backgroundColor: 'rgba(230, 126, 34 , 0.8)',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '14px', // Adjust font size if necessary
        textAlign: 'center',
    },
    button2: {
        width: '45%', // Reduced width to fit two buttons per row
        padding: '10px',
        margin: '5px', // Adjust margin for spacing
        backgroundColor: 'rgba(196, 196, 196 , 0.8)',
        color: 'black',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '14px', // Adjust font size if necessary
        textAlign: 'center',
    },
    button3: {
        width: '45%', // Reduced width to fit two buttons per row
        padding: '10px',
        margin: '5px', // Adjust margin for spacing
        backgroundColor: 'rgba(225,185,239, 0.8)',
        color: 'black',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '14px', // Adjust font size if necessary
        textAlign: 'center',
    },
    modeButton: {
        width: '80%',
        padding: '10px',
        margin: '10px 0',
        backgroundColor: 'rgba(196, 196, 196 , 0.8)',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '16px',
        textAlign: 'center',
    },
    viewButton: {
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        backgroundColor: 'rgba(100, 100, 100, 0.8)',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
    },
    circularContainer: {
        width: '130px',
        height: '130px',
        position: 'relative',
        margin : "20px 50px 0"
    },
    top: {
        top: '0',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    left: {
        top: '50%',
        left: '0',
        transform: 'translate(-50%, -50%)',
    },
    center: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(239, 189, 255, 0.8)',
    },
    right: {
        top: '50%',
        right: '0',
        transform: 'translate(50%, -50%)',
    },
    bottom: {
        bottom: '0',
        left: '50%',
        transform: 'translate(-50%, 50%)',
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap', // Allow wrapping of buttons
        justifyContent: 'space-between', // Ensure buttons are spaced evenly
        width: '100%',
    },
    textureContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        margin: '10px 0',
    },
    textureCircle: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        cursor: 'pointer',
    },
    imageButton: {
        width: '35%', // Matches original button width
        margin: '5px', // Adjust margin for spacing
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional background color
        borderRadius: '15px',
        padding: '10px',
        transition: 'background-color 0.3s',
    },
    img: {
        width: '50%',
        borderRadius: '10px',
        marginBottom: '2px', // Space between image and label
    },
    label: {
        color: 'white',
        fontSize: '14px', // Adjust font size if necessary
        textAlign: 'center',
        // backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for better readability
        padding: '4px 4px',
        borderRadius: '5px',
    },

};



export default Panel;

