import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Panel from './Panel';
import SidebarMenu from './SidePanel';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { GroundedSkybox } from 'three/addons/objects/GroundedSkybox.js'
import { DragControls } from 'three/addons/controls/DragControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
// import GUI from 'lil-gui'
import CANNON from 'cannon';
import CameraControls from "camera-controls";
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { degToRad } from 'three/src/math/MathUtils';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';


const Canvas = () => {

    console.log('render')

    // An example of storing initial states
    let initialStates = {
        objects: [],
        defaultModels: {} // Add your initial model data here
    };
    CameraControls.install({ THREE: THREE });
    let movement = { forward: false, backward: false, left: false, right: false };
    let speed = 2.0;
    const canvasRef = useRef();
    const modelRef = useRef([]);
    const sceneRef = useRef();
    const cameraRef = useRef();
    const baseColor = useRef();

    const cameraPersRef = useRef();
    const cameraOrthoRef = useRef();
    const orbitRef = useRef();
    const firstPersonControl = useRef();
    const dragControlRef = useRef();
    const transformControlRef = useRef();
    const renderer = useRef();
    const firstLoad = useRef(true);
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let selectedObject = null;
    let hoveredObject = null;
    let currentIntersect = null;
    const originalMaterials = new Map();
    let INTERSECTED;
    // Limits for x, y, z
    const minLimit = new THREE.Vector3(-5, 0.1, -5);
    const maxLimit = new THREE.Vector3(5, 5, 5);
    const [models, setModels] = useState([]);
    // const gui = new GUI();
    let world;
    const meshes = [];
    const bodies = [];
    let boundingBoxes = [];
    let draggableObjects = [];
    let sectionGroups = [];
    const [sectionGroupsChange, setSectionGroupsChange] = useState([]);
    const [isModelLoaded, setIsModelLoaded] = useState(false);

    let previousPosition = new THREE.Vector3();  // Track last known position

    // Movement parameters
    let moveForward = false;
    let moveBackward = false;
    let moveLeft = false;
    let moveRight = false;
    let rotateLeft = false;
    let rotateRight = false;

    let moveSpeed = 0.1;
    let rotateSpeed = 0.02;

    const [cameraType, setCameraType] = useState('perspective');
    // const canvas = document.createElement('canvas');
    // renderer.current = new THREE.WebGLRenderer({ canvas, antialias: true });
    let composer, renderPass, bloomPass, perspectiveCamera1;

    function constrainPosition(object, min, max) {
        console.log(min, max)
        object.position.x = THREE.MathUtils.clamp(object.position.x, min.x, max.x);
        object.position.y = THREE.MathUtils.clamp(object.position.y, min.y, max.y);
        object.position.z = THREE.MathUtils.clamp(object.position.z, min.z, max.z);

        console.log('object pos', object.position)
    }


    const init = () => {
        console.log('init')

        world = new CANNON.World();
        world.gravity.set(0, -9.82, 0)

        // Increase iterations and step accuracy to fix "passing through" issues
        world.broadphase = new CANNON.NaiveBroadphase();
        world.solver.iterations = 10; // Increase solver iterations
        world.solver.tolerance = 0.001;


        // const sphere = new CANNON.Sphere(0.5)
        // const sphereBody = new CANNON.Body({
        //     mass: 1,
        //     position: new CANNON.Vec3(0, 3, 0),
        //     shape: sphere
        // })
        // world.addBody(sphereBody);
        const width = canvasRef.current.clientWidth;
        const height = canvasRef.current.clientHeight;
        const aspect = width / height;

        cameraPersRef.current = new THREE.PerspectiveCamera(45, aspect, 0.01, 5000);
        cameraRef.current = cameraPersRef.current

        sceneRef.current = new THREE.Scene()
        const frustumSize = 10;
        cameraOrthoRef.current = new THREE.OrthographicCamera(
            - 1 * aspect, 1 * aspect, 1, - 1, 0.1, 1000
        );
        // orthographicCamera1.position.set(0, 1, 5);

        renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.current.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);

        renderer.current.setClearColor('#BBE9FF');
        renderer.current.shadowMap.enabled = true
        renderer.current.shadowMap.type = THREE.PCFSoftShadowMap; // Use soft shadows
        renderer.current.physicallyCorrectLights = true;
        renderer.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        // renderer.current.outputEncoding = THREE.sRGBEncoding;
        // renderer.current.toneMapping = THREE.NoToneMapping;
        renderer.current.toneMapping = THREE.ACESFilmicToneMapping
        // renderer.current.toneMapping = THREE.ReinhardToneMapping
        renderer.current.toneMappingExposure = 1.5
        canvasRef.current.appendChild(renderer.current.domElement);

        // renderer.current.toneMapping = THREE.ACESFilmicToneMapping;
        // renderer.current.toneMappingExposure = 0.7;
        // renderer.current.outputEncoding = THREE.sRGBEncoding;
        // renderer.current.textureEncoding = THREE.sRGBEncoding; 


        const environment = new RoomEnvironment();
        const pmremGenerator = new THREE.PMREMGenerator(renderer.current);
        sceneRef.current.background = new THREE.Color(0xbbbbbb);
        sceneRef.current.environment = pmremGenerator.fromScene(environment).texture;


        // Load HDR environment map
        // const rgbeLoader = new RGBELoader();
        // // rgbeLoader.load('./hdr/cabin_2k.hdr', (texture) => {

        // // rgbeLoader.load('./hdr/noon_grass_4k.hdr', (texture) => {
        // rgbeLoader.load('./hdr/Cannon_Exterior.hdr', (texture) => {
        //     // rgbeLoader.load('./hdr/royal_esplanade_2k.hdr', (texture) => {
        //     // rgbeLoader.load('./hdr/studio_small_03_2k.hdr', (texture) => {
        //        texture.mapping = THREE.EquirectangularReflectionMapping;
        //     //    texture.mapping = THREE.ACESFilmicToneMapping
        //     //    texture.colorSpace =THREE.SRGBColorSpace
        // //    renderer.current.textureEncoding = THREE.sRGBEncoding; 

        //     sceneRef.current.background = texture;
        //     sceneRef.current.backgroundBlurriness = 0.3
        //     sceneRef.current.backgroundIntensity = 2
        //     sceneRef.current.environment = texture;
        //     sceneRef.current.environmentIntensity = 1.8

        //     // const skybox = new GroundedSkybox(texture, 15, 70)
        //     // skybox.material = new THREE.MeshPhysicalMaterial({
        //     //     transmission: 1,
        //     //     roughness: 0.4,
        //     // })
        //     // skybox.position.y = 15
        //     // // skybox.position.y = 15
        //     // sceneRef.current.add(skybox)
        // });




        // Ground plane setup


        // Lighting
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
        ambientLight.castShadow = true
        // sceneRef.current.add(ambientLight);
        // const pointLight = new THREE.PointLight(0xffffff, 1);
        // pointLight.position.set(10, 10, 10);
        // scene.add(pointLight);

        const gridHelper = new THREE.GridHelper(50, 50);
        gridHelper.position.set(0, 0, 0)
        sceneRef.current.add(gridHelper);

        cameraPersRef.current.position.z = 5;


        orbitRef.current = new OrbitControls(cameraRef.current, renderer.current.domElement);
        orbitRef.current.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        orbitRef.current.dampingFactor = 0.09;
        orbitRef.current.target.set(0, 0, 0)
        orbitRef.current.screenSpacePanning = false;
        // orbitRef.current.maxPolarAngle = Math.PI / 2;

        console.log('modelRef.current', modelRef.current)

        transformControlRef.current = new TransformControls(cameraRef.current, renderer.current.domElement);
        sceneRef.current.add(transformControlRef.current);

        const pointLight = new THREE.DirectionalLight(0xffffff, 1);
        pointLight.position.set(10, 20, 10);
        pointLight.castShadow = true;
        // pointLight.shadow.mapSize.width = 4096;  // Higher resolution shadow map
        // pointLight.shadow.mapSize.height = 4096;
        // sceneRef.current.add(pointLight);

        // Lighting setup

        const axesHelper = new THREE.AxesHelper(1000);
        axesHelper.position.set(0, 0, 0)
        sceneRef.current.add(axesHelper);


        const fillLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.4);
        // sceneRef.current.add(fillLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
        // directionalLight.shadow.camera.far = 15;
        // directionalLight.position.set(0, 10, -5);
        // directionalLight.position.set(-4, 6.5, 2.5);
        // directionalLight.shadow.mapSize.set(1024, 1024)

        // directionalLight.scale.set(200,200,200);
        directionalLight.castShadow = true;  // Enable shadows for the light source
        // directionalLight.target.position.set(0, 4, 0)

        directionalLight.position.set(-4, 6, 2); // Experiment with different positions
        directionalLight.target.position.set(0, 0, 0);
        directionalLight.target.updateWorldMatrix();


        // Shadow properties
        directionalLight.shadow.mapSize.set(2048, 2048); // Increase for better quality
        directionalLight.shadow.radius = 4; // Increase to soften shadows
        // // Configure shadow camera to properly enclose the scene
        // directionalLight.shadow.camera.left = -10;
        // directionalLight.shadow.camera.right = 10;
        // directionalLight.shadow.camera.top = 10;
        // directionalLight.shadow.camera.bottom = -10;
        // directionalLight.shadow.camera.near = 0.1;
        // directionalLight.shadow.camera.far = 50;


        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(10, 0, 0)
        // sceneRef.current.add(cube);
        const cube2 = new THREE.Mesh(geometry, material);
        cube2.position.set(-10, 0, 0)
        // sceneRef.current.add(cube2);

        const cube3 = new THREE.Mesh(geometry, material);
        cube3.position.set(0, 5, 0)
        // sceneRef.current.add(cube3);

        const cube4 = new THREE.Mesh(geometry, material);
        cube4.position.set(0, 0, -10)
        // sceneRef.current.add(cube4);

        // // Small shadow bias to avoid shadow acne
        directionalLight.shadow.bias = -0.004;

        // Adjust the shadow frustum to avoid shadow extension and incorrect placement
        directionalLight.shadow.normalBias = 0.027; // Helps with fixing Peter Panning issue


        sceneRef.current.add(directionalLight);

        // createGroundPlane();


        const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
        // sceneRef.current.add(directionalLightCameraHelper)


        const helper = new THREE.CameraHelper(directionalLight.shadow.camera);
        // sceneRef.current.add(helper);

        let clock = new THREE.Clock()

        let oldElapsedTime = 0
        const animate = () => {
            console.log('animate')


            // checkCollisionsAndPreventOverlap();
            requestAnimationFrame(animate);

            // const delta = clock.getDelta();

            // if (cameraRef.current.controls) {
            //     const hasControlsUpdated = cameraRef.current.controls.update(delta);

            //     updateCameraMovement(delta);

            //     if (hasControlsUpdated) {
            //         renderer.current.render(sceneRef.current, cameraRef.current);
            //     }
            // }

            // if (firstPersonControl.current) {
            //     const delta = clock.getDelta();
            //     firstPersonControl.current.update(delta);
            // }

            // Movement logic
            const direction = new THREE.Vector3(); // To calculate direction of movement
            if (moveForward) {
                cameraRef.current.getWorldDirection(direction); // Get direction the camera is facing
                cameraRef.current.position.addScaledVector(direction, moveSpeed); // Move forward in that direction
            }

            if (moveBackward) {
                cameraRef.current.getWorldDirection(direction);
                cameraRef.current.position.addScaledVector(direction, -moveSpeed); // Move backward
            }

            if (moveLeft) {
                cameraRef.current.translateX(-moveSpeed); // Strafe left
            }
            if (moveRight) {
                cameraRef.current.translateX(moveSpeed); // Strafe right
            }

            // // Movement logic
            // if (moveForward) cameraRef.current.position.z -= moveSpeed;  // Move forward along the z-axis
            // if (moveBackward) cameraRef.current.position.z += moveSpeed; // Move backward along the z-axis
            // if (moveLeft) cameraRef.current.position.x -= moveSpeed;     // Strafe left along the x-axis
            // if (moveRight) cameraRef.current.position.x += moveSpeed;    // Strafe right along the x-axis

            // Rotation logic (yaw rotation)
            if (rotateLeft) cameraRef.current.rotation.y += rotateSpeed;  // Rotate left (yaw)
            if (rotateRight) cameraRef.current.rotation.y -= rotateSpeed; // Rotate right (yaw)



            // Sync Three.js meshes with Cannon.js physics bodies after dragging
            // for (let i = 0; i < bodies.length; i++) {
            //     const body = bodies[i];
            //     const mesh = meshes[i];
            //     mesh.position.copy(body.position);
            //     mesh.quaternion.copy(body.quaternion);
            // }
            // currentCamera.updateProjectionMatrix();

            // raycaster.setFromCamera(mouse, cameraRef.current);

            // const intersects = raycaster.intersectObjects(sceneRef.current.children);
            // console.log('intersects', intersects)

            // if (intersects?.length > 0) {

            //     console.log(INTERSECTED)

            //     if (INTERSECTED != intersects[0].object) {

            //         if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

            //         INTERSECTED = intersects[0].object;
            //         INTERSECTED.currentHex = INTERSECTED.material?.emissive.getHex();
            //         INTERSECTED.material.emissive.setHex(0xff0000);

            //     }

            // } else {

            //     if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

            //     INTERSECTED = null;

            // }

            if (orbitRef.current) orbitRef.current.update();


            renderer.current.render(sceneRef.current, cameraRef.current);



        };
        animate();




    }


    function onMouseClick(event) {
        // Calculate mouse position in normalized device coordinates
        mouse.x = (event.clientX / canvasRef.current.clientWidth) * 2 - 1;
        mouse.y = -(event.clientY / canvasRef.current.clientHeight) * 2 + 1;

        // Update the raycaster with the camera and mouse position
        // raycaster.setFromCamera(mouse, cameraRef.current);

        // // Calculate intersections with objects in the scene
        // const intersects = raycaster.intersectObjects(sceneRef.current.children);

        // if (intersects.length > 0) {
        //     if (selectedObject) {
        //         selectedObject.material = selectedObject.userData.originalMaterial; // Reset to original material

        //         // selectedObject.material.color.set(0x00ff00); // Reset color or any property
        //     }
        //     // Do something with the intersected object
        //     selectedObject = intersects[0].object;
        //     console.log('Intersection found:', selectedObject);
        //     // Example: Change the selected object's color
        //     // selectedObject.material.color.set(0xff0000);
        //     selectedObject.material = new THREE.MeshBasicMaterial({ color: 0xff0000 });

        // }
        // else {
        //     // If no objects are intersected and there's a previously selected object, deselect it
        //     if (selectedObject) {
        //         selectedObject.material = selectedObject.userData.originalMaterial; // Reset to original material
        //         // selectedObject.material.color.set(0x00ff00); // Reset color or any property
        //         selectedObject = null; // Clear the selected object
        //     }
        // }

    }


    function onClick(event) {

        console.log('Click on hovered....', INTERSECTED)


        if (INTERSECTED) {
            selectedObject = INTERSECTED

            // selectedObject.material = new THREE.MeshStandardMaterial({
            //                     transparent: true,
            //                     opacity: 0.7,
            //                     color: 0x98d7c5
            //                 });
            console.log('Click on hovered....', selectedObject)
        }
    }
    function onMouseMove(event) {
        console.log()
        // Calculate mouse position in normalized device coordinates

        //pixels of mouse
        mouse.x = event.clientX / canvasRef.current.clientWidth * 2 - 1.5;
        mouse.y = -(event.clientY / canvasRef.current.clientHeight) * 2 + 1;


        // Update the raycaster with the camera and mouse position
        // raycaster.setFromCamera(mouse, cameraRef.current);

        // // Calculate intersections with objects in the scene

        // const intersects = raycaster.intersectObjects(sceneRef.current.children);

        // if (intersects?.length > 0) {

        //     const intersectedObject = intersects[0].object;


        //     if (currentIntersect === null) {
        //         hoveredObject=intersectedObject

        //         if (hoveredObject && hoveredObject.material) {
        //             hoveredObject.userData.originalMaterial = hoveredObject.material.clone();
        //             hoveredObject.material = new THREE.MeshStandardMaterial({
        //                 transparent: true,
        //                 opacity: 0.7,
        //                 color: 0xc57bf9
        //             });
        //         }
        //         console.log('mouse enter')


        //     }
        //     currentIntersect =intersectedObject 
        //     // intersects.forEach(element => {
        //     //     console.log('element', element.object)
        //         // element.object.material.color.set(0x00ff00)
        //         // MeshStandardMaterial
        //         // element.object.material = new THREE.MeshStandardMaterial({ transparent: true, opacity: 0.7, color: 0xc57bf9 });

        //     // });



        // }
        // else {

        //     if (currentIntersect != null && hoveredObject) {
        //         if (hoveredObject && hoveredObject.material) {
        //             hoveredObject.material = originalMaterials.get(hoveredObject.uuid);

        //             // hoveredObject.material = hoveredObject.userData.originalMaterial;

        //         }
        //         console.log('mouse leave');
        //         hoveredObject = null;
        //     }
        //     currentIntersect = null;

        //     // if (currentIntersect) {
        //     //     console.log('mouse leave')
        //     //     if (hoveredObject) {
        //     //         hoveredObject.material = currentIntersect.object.userData.originalMaterial
        //     //     }
        //     //     // selectedObject.material = selectedObject.userData.originalMaterial;
        //     // }
        //     // currentIntersect = null
        // }
    }

    // Create a ground plane in both Three.js and Cannon.js
    const createGroundPlane = () => {
        // Three.js plane
        const planeGeometry = new THREE.PlaneGeometry(100, 100);
        const planeMaterial = new THREE.MeshStandardMaterial({ color: 0x808080 });
        const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
        planeMesh.rotation.x = -Math.PI / 2;
        sceneRef.current.add(planeMesh);

        // Cannon.js ground plane
        const groundShape = new CANNON.Plane();
        const groundBody = new CANNON.Body({ mass: 0 });
        groundBody.addShape(groundShape);
        groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);
        groundBody.position.set(0, 0, 0);
        world.addBody(groundBody);
    };


    const addModel = (modelPath) => {

        console.log('add new', modelPath)

        const loader2 = new GLTFLoader();
        loader2.load(modelPath, (gltf) => {

            gltf.scene.position.set(0, 0.5, 0)
            console.log(' modelRef.current', modelRef.current)
            // modelRef.current.push(gltf.scene)
            sceneRef.current.add(gltf.scene);

            draggableObjects.push(gltf.scene);




            // setModels((prevModels) => {
            //     const updatedModels = [...prevModels, newModel];
            //     dragControlRef.current.objects = updatedModels;
            //     return updatedModels;
            //   });

            sceneRef.current.traverse((child) => {
                if (child.isMesh) {
                    meshes.push(child);

                    if (child.material.isMeshStandardMaterial) {
                        child.castShadow = true
                        child.receiveShadow = true
                    }
                }

            })

            console.log('bodies', bodies)



        }, undefined, (error) => {
            console.error('An error happened', error);
        });

    }

    function loadTexture(meshSize, url) {
        return new Promise((resolve, reject) => {
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(
                url,
                (texture) => {
                    // Set texture properties
                    texture.wrapS = THREE.RepeatWrapping;
                    texture.wrapT = THREE.RepeatWrapping;

                    // Calculate and set the repeat for the texture
                    const repeatX = meshSize.x / texture.image.width;
                    const repeatY = meshSize.y / texture.image.height;
                    texture.repeat.set(repeatX, repeatY);

                    texture.colorSpace = THREE.SRGBColorSpace;
                    console.log('ABC >> texture', texture);

                    resolve(texture);
                },
                undefined,  // onProgress callback, usually not needed
                (error) => {
                    console.error('Error loading texture:', error);
                    reject(error);
                }
            );
        });
    }




    const onTextureScale = async (item) => {

        console.log('texture scale', item)
        // Assume selectedObject is your object with a material and texture
        if (selectedObject.material.map) {
            // Access the texture
            const texture = selectedObject.material.map;

            // Set the texture repeat to desired scale, e.g., scale by 2 in both directions
            texture.repeat.set(item, item);

            // If needed, set the wrapS and wrapT to THREE.RepeatWrapping to ensure repeating works
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;

            // Optionally, update the texture
            texture.needsUpdate = true;
        }
        if (selectedObject.material.aoMap) {
            const texture = selectedObject.material.aoMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.normalMap) {
            const texture = selectedObject.material.normalMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.roughnessMap) {
            const texture = selectedObject.material.roughnessMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.displacementMap) {
            const texture = selectedObject.material.displacementMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.metalnessMap) {
            const texture = selectedObject.material.metalnessMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }




    }


    const onTextureRepeat = async (item) => {
        console.log('texture repeat ....', item)
        onTextureScale(item)



    }
    const onTexturePosition = async (item) => {

        console.log('texture Position', item)
        // Assume selectedObject is your object with a material and texture
        if (selectedObject.material.map) {
            // Access the texture
            const texture = selectedObject.material.map;
            texture.offset.set(item.x, item.y);
            // texture.rotation =selectedObject.material.map.rotation

            // Move texture 20% on X-axis and 30% on Y-axis
            // Optionally, update the texture
            texture.needsUpdate = true;
        }
        if (selectedObject.material.aoMap) {
            const texture = selectedObject.material.aoMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.normalMap) {
            const texture = selectedObject.material.normalMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.roughnessMap) {
            const texture = selectedObject.material.roughnessMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.displacementMap) {
            const texture = selectedObject.material.displacementMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.metalnessMap) {
            const texture = selectedObject.material.metalnessMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }




    }

    const onTextureRotate = async (item) => {

        console.log('texture rotate', item)
        // Assume selectedObject is your object with a material and texture
        if (selectedObject.material.map) {
            const texture = selectedObject.material.map;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.aoMap) {
            const texture = selectedObject.material.aoMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.normalMap) {
            const texture = selectedObject.material.normalMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.roughnessMap) {
            const texture = selectedObject.material.roughnessMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.displacementMap) {
            const texture = selectedObject.material.displacementMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.metalnessMap) {
            const texture = selectedObject.material.metalnessMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }




    }

    const onImageSelect = async (item) => {

        const floor1 = new THREE.Mesh(
            new THREE.PlaneGeometry(1, 1),
        );
        floor1.position.set(3.3, 0, -1);
        floor1.rotation.set(degToRad(90), 0, 0);
        sceneRef.current.add(floor1);
        const meshSize1 = new THREE.Vector3();
        floor1.geometry.computeBoundingBox();
        floor1.geometry.boundingBox.getSize(meshSize1);

        let mat = new THREE.MeshLambertMaterial({
            color: 0xff0000
        });

        const textureLoader = new THREE.TextureLoader();

        const textureـbaseColor1 = await textureLoader.loadAsync(item.baseColor);
        textureـbaseColor1.wrapS = THREE.RepeatWrapping;
        textureـbaseColor1.wrapT = THREE.RepeatWrapping;

        let width = textureـbaseColor1.image.width
        let height = textureـbaseColor1.image.height;

        let aspecRatio = width / height;

        const repeatX = meshSize1.x / (width / aspecRatio * 0.0002645833)
        let repeatY;
        let otherMax = Math.max(meshSize1.y, meshSize1.z)
        if (otherMax === meshSize1.y) {
            repeatY = meshSize1.y / (height / aspecRatio * 0.0002645833);

        }
        if (otherMax === meshSize1.z) {
            repeatY = meshSize1.z / (height / aspecRatio * 0.0002645833);
        }

        textureـbaseColor1.repeat.set(repeatX, repeatY);
        textureـbaseColor1.colorSpace = THREE.SRGBColorSpace;


        floor1.material = new THREE.MeshStandardMaterial({
            side: THREE.DoubleSide,
            map: textureـbaseColor1
        })

        // textureـbaseColor.anisotropy = renderer.current.capabilities.getMaxAnisotropy();
        // Calculate the mesh size along the desired axes
        const meshSize = new THREE.Vector3();
        selectedObject.geometry.computeBoundingBox();
        selectedObject.geometry.boundingBox.getSize(meshSize);
        const scaleX = selectedObject.scale.x;
        const scaleY = selectedObject.scale.y;
        const scaleZ = selectedObject.scale.z;
        const textureLoader2 = new THREE.TextureLoader();
        const textureـbaseColor2 = await textureLoader2.loadAsync(item.baseColor);
        textureـbaseColor2.wrapS = THREE.RepeatWrapping;
        textureـbaseColor2.wrapT = THREE.RepeatWrapping;
        let width2 = textureـbaseColor2.image.width
        let height2 = textureـbaseColor2.image.height;
        let aspecRatio2 = width2 / height2;
        let repeatY2;
        let mainMin = Math.min(meshSize.x, meshSize.y, meshSize.z)
        if (mainMin === meshSize.x) {
            meshSize.x = meshSize.z;
            meshSize.z = mainMin
        }
        let otherMax2 = Math.max(meshSize.y, meshSize.z)
        if (otherMax2 === meshSize.y) {
            repeatY2 = meshSize.y / (height2 / aspecRatio2 * 0.0002645833);

        }
        if (otherMax2 === meshSize.z) {
            repeatY2 = meshSize.z / (height2 / aspecRatio2 * 0.0002645833);
        }
        const repeatX2 = meshSize.x / (width2 / aspecRatio2 * 0.0002645833)
        textureـbaseColor2.repeat.set(repeatX2, repeatY2);
        textureـbaseColor2.colorSpace = THREE.SRGBColorSpace;

        let metalnessMap, textureـaoMap, textureـnormalMap, roughnessMap, displacementMap
        if (item.aomap.length > 0) {
            textureـaoMap = await textureLoader.loadAsync(item.aomap);
            textureـaoMap.wrapS = THREE.RepeatWrapping;
            textureـaoMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_aop = textureـaoMap.image.width / textureـaoMap.image.height;
            let repeatY_aomap;
            const repeatX_aomap = meshSize.x /
                (textureـaoMap.image.width / aspecRatio_aop * 0.0002645833);

            let otherMax2 = Math.max(meshSize.y, meshSize.z)
            if (otherMax2 === meshSize.y) {
                repeatY_aomap = meshSize.y / (textureـaoMap.image.height / aspecRatio_aop * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_aomap = meshSize.z / (textureـaoMap.image.height / aspecRatio_aop * 0.0002645833);
            }
            textureـaoMap.repeat.set(repeatX_aomap, repeatY_aomap);
            textureـaoMap.colorSpace = THREE.SRGBColorSpace;

        }

        if (item.normalmap.length > 0) {
            textureـnormalMap = await textureLoader.loadAsync(item.normalmap);
            textureـnormalMap.wrapS = THREE.RepeatWrapping;
            textureـnormalMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_normalMap = textureـnormalMap.image.width / textureـnormalMap.image.height;
            let repeatY_normalmap;
            const repeatX_normalmap = meshSize.x /
                (textureـnormalMap.image.width / aspecRatio_normalMap * 0.0002645833)

            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_normalmap = meshSize.y / (textureـnormalMap.image.height / aspecRatio_normalMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_normalmap = meshSize.z / (textureـnormalMap.image.height / aspecRatio_normalMap * 0.0002645833);
            }


            textureـnormalMap.repeat.set(repeatX_normalmap, repeatY_normalmap);
            textureـnormalMap.colorSpace = THREE.SRGBColorSpace;
        }


        if (item.roughness.length > 0) {

            roughnessMap = await textureLoader.loadAsync(item.roughness);
            roughnessMap.wrapS = THREE.RepeatWrapping;
            roughnessMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_roughnessMap = roughnessMap.image.width / roughnessMap.image.height;

            let repeatY_roughnessMap;

            const repeatX_roughnessMap = meshSize.x /
                (roughnessMap.image.width / aspecRatio_roughnessMap * 0.0002645833)


            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_roughnessMap = meshSize.y / (roughnessMap.image.height / aspecRatio_roughnessMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_roughnessMap = meshSize.z / (roughnessMap.image.height / aspecRatio_roughnessMap * 0.0002645833);
            }

            roughnessMap.repeat.set(repeatX_roughnessMap, repeatY_roughnessMap);
            roughnessMap.colorSpace = THREE.SRGBColorSpace;
        }

        if (item.displacement.length > 0) {
            displacementMap = await textureLoader.loadAsync(item.displacement);
            displacementMap.wrapS = THREE.RepeatWrapping;
            displacementMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_displacementMap = displacementMap.image.width / displacementMap.image.height;
            let repeatY_displacementMap;

            const repeatX_displacementMap = meshSize.x /
                (displacementMap.image.width / aspecRatio_displacementMap * 0.0002645833)


            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_displacementMap = meshSize.y / (displacementMap.image.height / aspecRatio_displacementMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_displacementMap = meshSize.z / (displacementMap.image.height / aspecRatio_displacementMap * 0.0002645833);
            }

            displacementMap.repeat.set(repeatX_displacementMap, repeatY_displacementMap);
            displacementMap.colorSpace = THREE.SRGBColorSpace;
        }

        if (item.metallic.length > 0) {
            metalnessMap = await textureLoader.loadAsync(item.metallic);
            metalnessMap.wrapS = THREE.RepeatWrapping;
            metalnessMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_metalnessMap = metalnessMap.image.width / metalnessMap.image.height;


            let repeatY_metalnessMap;

            const repeatX_metalnessMap = meshSize.x /
                (metalnessMap.image.width / aspecRatio_metalnessMap * 0.0002645833)

            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_metalnessMap = meshSize.y / (metalnessMap.image.height / aspecRatio_metalnessMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_metalnessMap = meshSize.z / (metalnessMap.image.height / aspecRatio_metalnessMap * 0.0002645833);
            }

            metalnessMap.repeat.set(repeatX_metalnessMap, repeatY_metalnessMap);
            metalnessMap.colorSpace = THREE.SRGBColorSpace;

        }

        const material = new THREE.MeshStandardMaterial({
            map: textureـbaseColor2,
            aoMap: textureـaoMap,
            normalMap: textureـnormalMap,
            roughnessMap: roughnessMap,
            displacementMap: displacementMap,
            displacementScale: 0.1,
            metalnessMap: metalnessMap,
            // envMapIntensity: 0.8,
            //  roughness: 0.5, // Base material roughness
            // metalness: 0.5 
        });

        selectedObject.material = material;

        // Update geometry for correct aoMap use, if needed
        if (textureـaoMap) {
            selectedObject.geometry.attributes.uv2 = selectedObject.geometry.attributes.uv;
        }

    };



    const orbitMode = () => {
        orbitRef.current.enabled = true
        transformControlRef.current.detach();
        transformControlRef.current.enabled = false
        if (dragControlRef.current) {
            dragControlRef.current.enabled = false;
            dragControlRef.current = ""
        }



    }
    const deleteSelected = () => {

        console.log('delete', selectedObject)
        if (selectedObject) {
            transformControlRef.current.detach();
            sceneRef.current.remove(sceneRef.current.getObjectByName(selectedObject.name));

            let removePart = sceneRef.current.getObjectByName(selectedObject.name)
            if (removePart.parent) {
                let parent = removePart.parent;
                parent.remove(removePart);
            }

            selectedObject = null;

        }
    }


    const transformMode = () => {

        dragControlRef.current.enabled = false
        transformControlRef.current.enabled = true


        transformControlRef.current.attach(selectedObject)

        transformControlRef.current.addEventListener('mouseDown', () => {
            orbitRef.current.enabled = false;
        });

        transformControlRef.current.addEventListener('mouseUp', () => {
            orbitRef.current.enabled = true;

        });

        transformControlRef.current.addEventListener('objectChange', () => {
            constrainPosition(selectedObject, minLimit, maxLimit);
        });

        window.addEventListener('keydown', (event) => {
            switch (event.key) {
                case 'r': // Press 'r' to switch to rotation mode
                    transformControlRef.current.setMode('rotate');
                    break;
                case 's': // Press 's' to switch to scale mode
                    transformControlRef.current.setMode('scale');
                    break;
                case 't': // Press 't' to switch to translate mode
                    transformControlRef.current.setMode('translate');
                    break;
            }
        });


    }

    // بررسی برخوردها و جلوگیری از overlap
    // function checkCollisionsAndPreventOverlap() {
    //     for (let i = 0; i < meshes.length; i++) {
    //         boundingBoxes[i].setFromObject(meshes[i]);  // به‌روزرسانی BoundingBox هر مش

    //         for (let j = 0; j < meshes.length; j++) {
    //             if (i !== j && boundingBoxes[i].intersectsBox(boundingBoxes[j])) {
    //                 console.log(`Collision detected between object ${i + 1} and object ${j + 1}`);

    //                 // برگرداندن مش به موقعیت قبلی
    //                 meshes[i].position.copy(previousPositions[i]);

    //                 // به‌روزرسانی BoundingBox بعد از برگرداندن به موقعیت قبلی
    //                 boundingBoxes[i].setFromObject(meshes[i]);
    //             }
    //         }

    //         // ذخیره موقعیت فعلی به عنوان موقعیت قبلی برای دور بعدی
    //         previousPositions[i].copy(meshes[i].position);
    //     }
    // }

    // تابع بررسی برخورد
    // function checkCollisions() {
    //     for (let i = 0; i < meshes.length; i++) {
    //         boundingBoxes[i].setFromObject(meshes[i]);  // به‌روزرسانی BoundingBox هر آبجکت
    //         for (let j = 0; j < meshes.length; j++) {
    //             if (i !== j && boundingBoxes[i].intersectsBox(boundingBoxes[j])) {
    //                 console.log(`Collision detected between object ${i + 1} and object ${j + 1}`);
    //                 // اینجا می‌توانید برخورد را مدیریت کنید، مثلاً آبجکت را به موقعیت قبلی برگردانید
    //             }
    //         }
    //     }
    // }
    // Check for collisions using Box3
    function checkCollisions(draggedObject) {
        for (let i = 0; i < draggableObjects.length; i++) {
            const object = draggableObjects[i];

            // Skip checking collision with itself
            if (object === draggedObject || !object.boundingBox) continue;


            // If bounding boxes intersect, there's a collision
            if (draggedObject.boundingBox.intersectsBox(object.boundingBox)) {
                return true;  // Collision detected
            }
        }
        return false;  // No collision
    }

    const dragMode = () => {

        transformControlRef.current.enabled = false
        transformControlRef.current.detach();
        if (dragControlRef.current) dragControlRef.current.enabled = true;

        dragControlRef.current = new DragControls(draggableObjects, cameraRef.current, renderer.current.domElement);

        // Add event listeners for dragging
        dragControlRef.current.addEventListener('dragstart', function (event) {
            selectedObject = event.object
            orbitRef.current.enabled = false
            const draggedObject = event.object;
            previousPosition.copy(draggedObject.position);
        });
        dragControlRef.current.addEventListener('drag', function (event) {
            selectedObject = event.object
            orbitRef.current.enabled = false
            const draggedObject = event.object;

            if (draggedObject.isMesh) {
                if (!draggedObject.boundingBox) {
                    draggedObject.boundingBox = new THREE.Box3();
                }
                draggedObject.boundingBox.setFromObject(draggedObject);

                // Check for collisions
                if (checkCollisions(draggedObject)) {
                    // If a collision is detected, revert to the previous position and turn red
                    draggedObject.position.copy(previousPosition);
                    draggedObject.material.color.set(0xff0000); // Change color to red
                } else {
                    // If no collision, save the new position as safe
                    previousPosition.copy(draggedObject.position);
                    draggedObject.material.color.set(0x00ff00); // Reset color to green or default
                }


                // const draggedBox = new THREE.Box3().setFromObject(draggedObject);
                // draggedBox.applyMatrix4(draggedObject.matrixWorld);  // Account for world transformations

                // meshes.forEach(otherObject => {
                //     if (otherObject !== draggedObject) {
                //         const otherBox = new THREE.Box3().setFromObject(otherObject);
                //         otherBox.applyMatrix4(otherObject.matrixWorld);

                //         if (draggedBox.intersectsBox(otherBox)) {
                //             // Revert position or stop movement if collision is detected
                //             draggedObject.position.copy(lastValidPosition);
                //             console.log('Collision detected, reverting position.');
                //         }
                //     }
                // });

            }

        });

        dragControlRef.current.addEventListener('dragend', function (event) {
            console.log('Drag ended: ', event.object);
            orbitRef.current.enabled = true
            // lastValidPosition = event.object.position.clone();

        });


    }

    const onGroupSelect = (item) => {
        console.log('group click', item)

        sceneRef.current.traverse((object) => {
            if (object.type === "Group") {
                if (object.name === item.name) {
                    // This is the group you want to keep visible
                    object.traverse((child) => {
                        if (child.isMesh) {
                            // Reset the material of the active group to its original state
                            child.material.transparent = false;
                            child.material.opacity = 1;
                        }
                    });
                } else {
                    // For all other groups, make their materials transparent or hidden
                    object.traverse((child) => {
                        if (child.isMesh) {
                            // Make the material transparent like glass
                            child.material.transparent = true;
                            child.material.opacity = 0.1;  // Adjust this value for more/less transparency
                        }
                    });
                }
            }



        })

    }

    const loadSectionList = () => {

            console.log('array list sections', isModelLoaded)

            // if(isModelLoaded){
            //     console.log('1')
            //     setSectionGroupsChange(sectionGroups);

            // }



        }

        const loadModel = useCallback((modelPath) => {
            // (modelPath) => {

            const loader = new GLTFLoader();
            loader.load(modelPath, (gltf) => {
                if (modelRef.current) {
                    sceneRef.current.remove(modelRef.current);
                }


                console.log('data gltf', gltf)

                modelRef.current = gltf.scene
                sceneRef.current.add(gltf.scene);


                // const test = gltf.scene.children.map(el => {
                //     if(el.type === "Group") {
                //         return el
                //     }
                // })

                // console.log('test',test)

                //  gltf.scene.children.forEach(element => {
                //     if(element.type === "Group"){
                //         sectionGroups.push(element)
                //     }

                // });

                // setSectionGroupsChange(test)
                setIsModelLoaded(true)



                initialStates.defaultModels = gltf.scene


                initialStates.objects = [];


                // gltf.scene.traverse((child) => {
                //     if (child.type === "Group" || child.type === "Mesh" || child.type === "Object3D") {

                //         if (child.isMesh) {

                //             initialStates.objects.push({
                //                 object: child,
                //                 position: child.position.clone(),
                //                 rotation: child.rotation.clone(),
                //                 scale: child.scale.clone(),
                //                 material: child.material.clone() // Assuming material is not linked to other objects
                //             });

                //             child.boundingBox = new THREE.Box3().setFromObject(child);
                //             draggableObjects.push(child);
                //             if (child.material.isMeshStandardMaterial) {
                //                 child.castShadow = true
                //                 child.receiveShadow = true
                //             }
                //         }
                //     }
                //     else {
                //         console.log('child n=out if>>', child.name, child.type)

                //     }



                // })



                console.log('filter objects >>', initialStates.objects)



            }, undefined, (error) => {
                console.error('An error happened', error);
            });

            // }
        }, []);

        const changeTexture = (textureURL) => {
            const textureloader = new THREE.TextureLoader();
            let map1 = textureloader.load(textureURL.diff);
            // map1.rotation=10
            let normalMap1 = textureloader.load(textureURL.norgl);
            let height = textureloader.load(textureURL.height);
            let roughnessmp = textureloader.load(textureURL.roughness);
            // let aoMap1 = textureloader.load(textureURL.aomap);
            map1.colorSpace = THREE.SRGBColorSpace
            height.colorSpace = THREE.SRGBColorSpace
            roughnessmp.colorSpace = THREE.SRGBColorSpace
            // aoMap1.colorSpace = THREE.SRGBColorSpace
            // let obj = sceneRef.current.getObjectByName('Object_5002')
            // obj.material.map = map1

            normalMap1.wrapS = THREE.RepeatWrapping
            normalMap1.wrapT = THREE.RepeatWrapping

            roughnessmp.wrapS = THREE.RepeatWrapping
            roughnessmp.wrapT = THREE.RepeatWrapping

            height.wrapS = THREE.RepeatWrapping
            height.wrapT = THREE.RepeatWrapping

            map1.wrapS = THREE.RepeatWrapping
            map1.wrapT = THREE.RepeatWrapping

            normalMap1.repeat.x = 8
            normalMap1.repeat.y = 8

            map1.repeat.x = 8
            map1.repeat.y = 8

            height.repeat.x = 8
            height.repeat.y = 8

            roughnessmp.repeat.x = 8
            roughnessmp.repeat.y = 8


            selectedObject.material = new THREE.MeshStandardMaterial({
                map: map1,
                normalMap: normalMap1,
                // normalScale: new THREE.Vector2(1, 0.2),
                // aoMap: aoMap1,
                color: 0x6ac0ff,
                // aoMapIntensity: 1,
                // roughnessMap: aoMap1,
                // metalnessMap: aoMap1,
                displacementMap: height,
                displacementScale: 0.005
            })
            const floor = new THREE.Mesh(
                new THREE.PlaneGeometry(8, 8),
                new THREE.MeshStandardMaterial({
                    map: map1,
                    color: 0x6ac0ff,
                    normalMap: normalMap1,
                    roughnessMap: roughnessmp,
                    displacementMap: height,
                    displacementScale: 0.005
                })
            )
            floor.position.set(1, 5, -5)
            // sceneRef.current.add(floor)


        }



        const resetScene = () => {


            // Remove all objects from the scene
            sceneRef.current.clear();


            initialStates.objects.forEach((state) => {

                console.log('initialStates.objects', state.object.name, state.object.type)

                let object = state.object;
                object.position.copy(state.position);
                object.rotation.copy(state.rotation);
                object.scale.copy(state.scale);
                object.material = state.material.clone(); // Reset material if needed
                sceneRef.current.add(object);
            });

        }

        const resetCamera = () => {
            const initialPosition = new THREE.Vector3(0, 5, 10); // Initial camera position
            const initialTarget = new THREE.Vector3(0, 0, 0); // Initial target position

            // Reset camera position
            cameraRef.current.position.copy(initialPosition);
            cameraRef.current.lookAt(initialTarget);

            // Reset OrbitControls target
            orbitRef.current.target.copy(initialTarget);
            orbitRef.current.update();
        };


        function updateCameraMovement(delta) {
            // Camera forward and right vectors
            const forward = new THREE.Vector3();
            const right = new THREE.Vector3();
            cameraRef.current.getWorldDirection(forward);
            forward.y = 0; // Prevent moving up or down
            forward.normalize();
            right.crossVectors(cameraRef.current.up, forward).normalize();

            // Movement logic
            // if (movement.forward) cameraRef.current.controls.moveTo(cameraRef.current.position.x + forward.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z + forward.z * delta * speed);
            // if (movement.backward) cameraRef.current.controls.moveTo(cameraRef.current.position.x - forward.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z - forward.z * delta * speed);
            // if (movement.left) cameraRef.current.controls.moveTo(cameraRef.current.position.x - right.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z - right.z * delta * speed);
            // if (movement.right) cameraRef.current.controls.moveTo(cameraRef.current.position.x + right.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z + right.z * delta * speed);

            if (movement.forward) cameraRef.current.controls.forward(0.25, true)
            if (movement.backward) cameraRef.current.controls.truck(-0.25, 0, true)
            // if (movement.left)   cameraRef.current.controls.distance--
            if (movement.left) cameraRef.current.controls.rotate(-0.1, 0, true)
            if (movement.right) cameraRef.current.controls.rotate(+0.1, 0, true)




        }

        const firstPersonMode = () => {

            // cameraMode('orthographic')
            console.log('cameraRef.current', cameraRef.current)
            firstPersonControl.current = new FirstPersonControls(cameraRef.current, renderer.current.domElement);
            firstPersonControl.current.movementSpeed = 5;
            firstPersonControl.current.lookSpeed = 0.01;
            firstPersonControl.current.lookVertical = false; // Allow looking up/down



            // Keydown event for movement and rotation
            document.addEventListener('keydown', (event) => {
                switch (event.code) {
                    case 'ArrowLeft':
                        rotateLeft = true;
                        break;
                    case 'ArrowRight':
                        rotateRight = true;
                        break;
                    case 'KeyW':
                        moveForward = true;
                        break;
                    case 'KeyS':
                        moveBackward = true;
                        break;
                    case 'KeyA':
                        moveLeft = true;
                        break;
                    case 'KeyD':
                        moveRight = true;
                        break;
                }
            });

            // Keyup event to stop movement or rotation
            document.addEventListener('keyup', (event) => {
                switch (event.code) {
                    case 'ArrowLeft':
                        rotateLeft = false;
                        break;
                    case 'ArrowRight':
                        rotateRight = false;
                        break;
                    case 'KeyW':
                        moveForward = false;
                        break;
                    case 'KeyS':
                        moveBackward = false;
                        break;
                    case 'KeyA':
                        moveLeft = false;
                        break;
                    case 'KeyD':
                        moveRight = false;
                        break;
                }
            });

            // const controls1 = new CameraControls(cameraRef.current, renderer.current.domElement);
            // controls1.dollyToCursor=true
            // cameraRef.current.controls=controls1

            // cameraRef.current.controls.setLookAt(5, 5, 5, 0, 0, 0);



            // Movement control variables
            // const speed = 2.0;
            // const movement = { forward: false, backward: false, left: false, right: false };

            // Add event listeners for movement keys (WASD)
            // window.addEventListener('keydown', (event) => {
            //     switch (event.code) {
            //         case 'KeyW': movement.forward = true; break;
            //         case 'KeyS': movement.backward = true; break;
            //         case 'KeyA': movement.left = true; break;
            //         case 'KeyD': movement.right = true; break;
            //     }
            // });

            // window.addEventListener('keyup', (event) => {
            //     switch (event.code) {
            //         case 'KeyW': movement.forward = false; break;
            //         case 'KeyS': movement.backward = false; break;
            //         case 'KeyA': movement.left = false; break;
            //         case 'KeyD': movement.right = false; break;
            //     }
            // });


            // cameraRef.current.controls.setLookAt(5, 5, 5, 0, 0, 0);

            // function onKeyDown(event) {
            //     switch (event.keyCode) {
            //         case 37:
            //         //     const newTargetPosition = new THREE.Vector3();
            //         //     cameraRef.current.controls.distance--
            //         //     cameraRef.current.controls.getPosition(newTargetPosition)
            //             //  camera.controls.minDistance = 1;
            //             //  camera.controls.maxDistance = 1;
            //             //  camera.controls.distance = 1;
            //             // cameraRef.current.controls.setLookAt(newTargetPosition.x--, newTargetPosition.y, newTargetPosition.z, 0, 0, 0);
            //             // camera.controls.moveTo(newTargetPosition.x,newTargetPosition.y,newTargetPosition.z)
            //             // cameraRef.current.controls.truckSpeed = 20;
            //             // cameraRef.current.controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
            //             // cameraRef.current.controls.touches.two = CameraControls.ACTION.TOUCH_ZOOM_TRUCK;
            //             //   camera.controls.right(0.25,true)
            //             cameraRef.current.controls.forward(0.25, true)
            //             console.log('left', cameraRef.current.controls)
            //             break;
            //         // case 38:
            //         //     //ok
            //         //     console.log('up')
            //         //     camera.controls.forward(0.25, true)
            //         //     break;
            //         // case 39:
            //         //     console.log('right')

            //         //     break;
            //         // case 40:
            //         //     //ok
            //         //     console.log('down')
            //         //     camera.controls.forward(-0.25, true)
            //         //     break;
            //         // default:
            //         //     break;
            //     }
            // }
            // document.addEventListener('keydown', onKeyDown, false);




        }

        const switchView = (viewmode) => {
            console.log('viewmode>>', viewmode)
            const camera = cameraRef.current;
            // const targetPosition = new THREE.Vector3();
            const targetPosition = new THREE.Vector3(0, 0, 0); // Origin


            switch (viewmode) {
                case 'top':
                    camera.position.set(0, 10, 0); // Above at 90 degrees
                    camera.lookAt(targetPosition);
                    // camera.up.set(0, 0, -1); // Set top view orientation
                    break;
                case 'front':

                    camera.position.set(0, 0, 10);
                    camera.lookAt(targetPosition);
                    camera.up.set(0, 1, 0); // Reset up vector
                    break;
                case 'back':
                    camera.position.set(0, 0, -10);
                    camera.lookAt(targetPosition);
                    camera.up.set(0, 1, 0); // Reset up vector
                    break;
                case 'left':
                    camera.position.set(-10, 0, 0);
                    camera.lookAt(targetPosition);
                    camera.up.set(0, 1, 0); // Reset up vector
                    break;
                case 'right':
                    camera.position.set(10, 0, 0);
                    camera.lookAt(targetPosition);
                    camera.up.set(0, 1,); // Reset up vector
                    break;
                default:
                    console.log('Unknown view:', viewmode);
                    return;
            }

            camera.lookAt(targetPosition);


        }
        const cameraMode = (mode) => {

            const targetPosition = new THREE.Vector3(0, 0, 0); // Origin


            console.log('mode>>', mode)

            if (mode == "perspective") {


                cameraRef.current = cameraPersRef.current
                cameraRef.current.position.set(0, 20, 0); // Above at 90 degrees
                cameraRef.current.lookAt(targetPosition);
                orbitRef.current.object = cameraRef.current
                // cameraRef.current.up.set(0, 0, -1);
                // orbitRef.current.target.set(targetPosition.x/, targetPosition.y, targetPosition.z); // Ensure controls target is correct
                orbitRef.current.update();

                renderer.current.shadowMap.enabled = true; // Enable shadows
                sceneRef.current.traverse((object) => {
                    if (object.isMesh) {
                        object.castShadow = true;
                        object.receiveShadow = true;
                    }
                });


            }
            else if (mode == "orthographic") {

                cameraRef.current = cameraOrthoRef.current
                cameraRef.current.position.set(0, 20, 0); // Above at 90 degrees
                cameraRef.current.lookAt(targetPosition);
                // cameraRef.current.up.set(0, 0, -1);
                orbitRef.current.object = cameraRef.current
                // orbitRef.current.target.set(targetPosition.x, targetPosition.y, targetPosition.z); // Ensure controls target is correct
                orbitRef.current.update();
                renderer.current.shadowMap.enabled = false; // Disable shadows
                sceneRef.current.traverse((object) => {
                    if (object.isMesh) {
                        object.castShadow = false;
                        object.receiveShadow = false;
                    }
                });



            }

            // if (mode === 'perspective' && cameraType !== 'perspective') {
            //     perspectiveCamera.updateProjectionMatrix();
            //     setCurrentCamera(perspectiveCamera);
            //     setCameraType('perspective');

            // } else if (mode === 'orthographic' && cameraType !== 'orthographic') {
            //     orthographicCamera.updateProjectionMatrix();
            //     setCurrentCamera(orthographicCamera);
            //     setCameraType('orthographic');

            // }

        }
        window.onresize = function () {

            console.log('resizing .....', cameraRef.current.aspect)
            cameraRef.current.aspect = canvasRef.current.clientWidth / canvasRef.current.clientHeight;
            cameraRef.current.updateProjectionMatrix();
            renderer.current.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
            firstPersonControl.current.handleResize();

        };

        useEffect(() => {
            console.log('useefect')
            if (firstLoad.current === true) {
                init()
                canvasRef.current.addEventListener('mousemove', onMouseMove);
                canvasRef.current.addEventListener('click', onClick);


            }
            firstLoad.current = false

        }, [])


        useEffect(() => {

            console.log('isloadmodel', modelRef.current)
            if (isModelLoaded) {
                const test = modelRef.current.children.map(el => {
                    if (el.type === "Group") {
                        return el
                    }
                })
                setSectionGroupsChange(test)
            }

        }, [isModelLoaded])



        return (
            <div style={{ display: 'flex', height: '100vh', width: '100%' }}>
                <Panel resetScene={resetScene} firstPersonMode={firstPersonMode} orbitMode={orbitMode} deleteSelected={deleteSelected} addModel={addModel} transformMode={transformMode} dragMode={dragMode} loadModel={loadModel} resetCamera={resetCamera} cameraMode={cameraMode} changeTexture={changeTexture} switchView={switchView} />
                <div ref={canvasRef} style={{ backgroundColor: '#BBE9FF', width: '100%', height: '100vh' }}></div>
                <SidebarMenu sectionGroupsChange={sectionGroupsChange} onItemSelect={onGroupSelect} onTextureRepeat={onTextureRepeat} onTexturePosition={onTexturePosition} onTextureRotate={onTextureRotate} onTextureScale={onTextureScale} onImageSelect={onImageSelect} />

            </div>
        );


    };

    export default Canvas;